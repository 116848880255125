import { CellLeaseDto } from '@/models/dto/CellLeaseDto';
import { Cell } from '@/models/entities/Cell';
import { User } from '@/models/entities/User';

export class CellLease implements CellLeaseDto {
  '@id': 'string';
  cell!: Pick<Cell, '@id' | 'cellId' | 'sequentialNumber'>;
  user!: Pick<User, '@id' | 'userId'>;

  public static createFromDto(dto: CellLeaseDto): CellLease {
    const obj: CellLease = {
      '@id': dto['@id'],
      cell: {
        '@id': dto.cell['@id'],
        cellId: dto.cell.cellId,
        sequentialNumber: dto.cell.sequentialNumber || 0,
      },
      user: {
        '@id': dto.user['@id'],
        userId: dto.user.userId,
      },
    };
    return Object.assign(new this(), obj);
  }
}
