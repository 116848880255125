import { PolygonDto } from '@/models/dto/PolygonDto';
import cloneDeep from 'lodash/cloneDeep';
import { DateAsString, TimeAsString } from '@/types/CommonTypes';
import { PolygonStatus } from '@/types/Statuses';
import { User } from '@/models/entities/User';
import { UserRoleDto } from '@/models/dto/UserRoleDto';
import dayjs from 'dayjs';
import { Cell } from '@/models/entities/Cell';
import { PolygonCreateDto } from '@/models/dto/PolygonCreateDto';
import { Camera } from '@/models/entities/Camera';
import { UserRoleIds } from '@/types/UserRoleIds';

export class Polygon implements PolygonDto {
  '@id': string;
  polygonId: number = 0;
  name: string = '';
  address: string = '';
  cells: Cell[] = [];
  cameras: Camera[] = [];
  description: string = '';
  createdAt?: string;
  updatedAt?: string;
  expirationDate?: DateAsString = undefined;
  expirationDateDt?: Date = undefined;
  startTime?: TimeAsString = undefined;
  finishTime?: TimeAsString = undefined;
  institutionName?: string = undefined;
  status?: PolygonStatus = undefined;
  userRolePolygons: {
    user: User;
    userRole: UserRoleDto;
  }[] = [];

  // eslint-disable-next-line no-useless-constructor,@typescript-eslint/no-empty-function
  constructor() {
    this.expirationDateDt = new Date();
  }

  public get expirationDateISO(): string {
    return dayjs(this.expirationDateDt)
      .format('YYYY-MM-DD');
  }

  get startTimeDt(): Date {
    return new Date(`1970-01-01T${this.startTime}`);
  }

  get finishTimeDt(): Date {
    return new Date(`1970-01-01T${this.finishTime}`);
  }

  // public set setExpirationDate(val: string) {
  //   this.expirationDateDt = dayjs(val).toDate();
  // }
  public static mkDateTimeFields(
    expirationDate?: DateAsString,
    startTime?: DateAsString,
    finishTime?: DateAsString,
  ) {
    const dates: Pick<Polygon, 'expirationDate' | 'expirationDateDt' | 'startTime' | 'finishTime'> = {};
    if (expirationDate) {
      dates.expirationDate = expirationDate;
      dates.expirationDateDt = new Date(expirationDate);
    }
    if (startTime) {
      dates.startTime = dayjs(startTime)
        .format('HH:mm');
    }
    if (finishTime) {
      dates.finishTime = dayjs(finishTime)
        .format('HH:mm');
    }
    return dates;
  }

  public static createFromDto(dto: Partial<PolygonDto>): Polygon {
    const clone = cloneDeep(dto);
    const polygon = Object.assign(new this(), clone, this.mkDateTimeFields(
      clone.expirationDate,
      clone.startTime,
      clone.finishTime,
    ));

    polygon.userRolePolygons = polygon.userRolePolygons.map((urp) => {
      return {
        user: User.createFromDto(urp.user),
        userRole: urp.userRole,
      };
    });

    polygon.cells = polygon.cells.map((c) => {
      return Cell.createFromDto(c);
    }).sort((a, b) => {
      if (a.sequentialNumber < b.sequentialNumber) {
        return -1;
      }
      if (a.sequentialNumber > b.sequentialNumber) {
        return 1;
      }
      return 0;
    });

    polygon.cameras = (dto.cameras || []).map((cam) => {
      return Camera.createFromDto(cam);
    });

    return polygon;
  }

  toCreateDto(): PolygonCreateDto {
    return {
      name: this.name,
      institutionName: this.institutionName,
      address: this.address,
      expirationDate: this.expirationDateDt?.toISOString(),
      startTime: this.startTimeDt.toISOString(),
      finishTime: this.finishTimeDt.toISOString(),
      description: this.description,
      status: this.status,
      cells: this.cells.map((c) => {
        const cell = c.toDto();
        if (this['@id']) {
          cell.polygon = this['@id'];
        }

        return cell;
      }),
    };
  }

  toUpdateDto(): PolygonCreateDto {
    return {
      name: this.name,
      institutionName: this.institutionName,
      address: this.address,
      expirationDate: this.expirationDateDt?.toISOString(),
      startTime: this.startTimeDt.toISOString(),
      finishTime: this.finishTimeDt.toISOString(),
      description: this.description,
      status: this.status,
    };
  }

  public static createCollectionFromDto(dtoCollection: PolygonDto[]) {
    return dtoCollection.map((dto) => this.createFromDto(dto));
  }

  public static getIriIdentifier(polygon: { '@id'?: PolygonIriIdentifier; polygonId?: number }): PolygonIriIdentifier {
    if (polygon['@id']) {
      return polygon['@id'];
    }
    if (polygon.polygonId) {
      return `/api/v1/polygons/${polygon.polygonId}`;
    }
    return '';
  }

  isUserAdmin(user: User) {
    if (!user || !user.userId) {
      return false;
    }
    return this.userRolePolygons.findIndex((ur) => {
      return ur.user?.userId === user.userId && ur.userRole.userRoleId === UserRoleIds.admin;
    }) !== -1;
  }
}
