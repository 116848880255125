import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';

export function createRouter(siteName: string, document: Document) {
  const routes: Array<RouteConfig> = [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: 'Обзор',
        icon: 'mdi-robot-mower-outline',
      },
    },
    {
      path: '/admin/users',
      name: 'admin-users',
      component: () => import('@/views/users/UserManagingView.vue'),
      meta: {
        title: 'Управление пользователями',
        icon: 'mdi-account-multiple',
      },
    },
    {
      path: '/admin/users/:id',
      name: 'user-profile',
      component: () => import('@/views/users/UserProfileView.vue'),
      meta: {
        title: 'Профиль пользователя',
        icon: 'mdi-account-multiple',
      },
    },
    {
      path: '/admin/users-add',
      name: 'admin-add-user',
      component: () => import('@/views/users/AddUserView.vue'),
      meta: {
        title: 'Добавить - Управление пользователями',
        icon: 'mdi-account-multiple',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/LoginFormView.vue'),
      meta: {
        title: 'Вход',
      },
    },
    {
      path: '/login/finish-registration/:tokenId',
      name: 'login-finish-registration',
      component: () => import('@/views/auth/FinishRegistrationView.vue'),
      meta: {
        title: 'Подтверждение регистрации',
      },
    },
    {
      path: '/login/restore-password',
      name: 'login-restore-password',
      component: () => import('@/views/auth/RestorePasswordView.vue'),
      meta: {
        title: 'Восстановление пароля',
      },
    },
    {
      path: '/login/finish-password-reset/:tokenId',
      name: 'finish-password-reset',
      component: () => import('@/views/auth/FinishPasswordResetView.vue'),
      meta: {
        title: 'Смена пароля',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/ProfileView.vue'),
      meta: {
        title: 'Профиль',
      },
    },
    {
      path: '/vpn-access',
      name: 'vpn-access',
      component: () => import('@/views/VpnAccessView.vue'),
      meta: {
        title: 'Ключи и доступы VPN',
      },
    },
    {
      path: '/polygons',
      name: 'polygons',
      component: () => import('@/views/polygons/PolygonsListView.vue'),
      meta: {
        title: 'Полигоны',
        icon: 'mdi-grid',
      },
    },
    {
      path: '/polygons/add',
      name: 'admin-add-polygon',
      component: () => import('@/views/polygons/AddPolygonView.vue'),
    },
    {
      path: '/polygons/:id',
      name: 'polygon',
      redirect: '/polygons/:id/home',
      component: () => import('@/views/polygons/PolygonView.vue'),
      children: [
        {
          path: 'home',
          name: 'polygon-home',
          component: () => import('@/views/polygons/PolygonHomeView.vue'),
          meta: {
            title: 'Общая информация',
            icon: 'mdi-information-outline',
          },
        },
        {
          path: 'schedule',
          name: 'polygon-schedule',
          component: () => import('@/views/polygons/PolygonScheduleView.vue'),
          meta: {
            title: 'Расписание полигона',
            icon: 'mdi-calendar-clock',
          },
          children: [
            {
              path: 'events/new',
              name: 'polygon-schedule-event-new',
              components: {
                dialogs: () => import('@/views/courses/CourseEditAdminFormView.vue'),
              },
            },
            {
              path: 'events/:courseId/edit',
              name: 'polygon-schedule-event-edit',
              components: {
                dialogs: () => import('@/views/courses/CourseEditAdminFormView.vue'),
              },
            },
          ],
        },
        {
          path: 'payload',
          name: 'polygon-payload',
          component: () => import('@/views/polygons/PolygonPayloadView.vue'),
          meta: {
            title: 'Управление ПН',
            icon: 'mdi-leak',
          },
        },
        {
          path: 'cameras',
          name: 'polygon-cameras',
          component: () => import('@/views/polygons/PolygonCamsView.vue'),
          meta: {
            title: 'Камеры',
            icon: 'mdi-cctv',
          },
          children: [
            {
              path: ':streamId',
              name: 'polygon-cameras-stream',
              components: {
                dialogs: () => import('@/views/polygons/PolygonCamsStreamView.vue'),
              },
            },
          ],
        },
        {
          path: 'stat',
          name: 'polygon-stat',
          meta: {
            title: 'Статистика',
            icon: 'mdi-chart-bar',
          },
        },
        {
          path: 'users',
          name: 'polygon-users',
          component: () => import('@/views/polygons/PolygonUsersView.vue'),
          meta: {
            title: 'Пользователи',
            icon: 'mdi-account-group',
          },
        },
      ],
    },
    {
      path: '/courses',
      name: 'courses',
      component: () => import('@/views/courses/MyCoursesListView.vue'),
      meta: {
        title: 'Мероприятия',
        icon: 'mdi-school-outline',
      },
      children: [
        {
          path: 'new',
          name: 'courses-new',
          components: {
            dialogs: () => import('@/views/courses/CourseEditFormView.vue'),
          },
          meta: {
            title: 'Добавление курса',
            icon: 'mdi-school-outline',
          },
        },
        {
          path: ':courseId/edit',
          name: 'course-edit',
          components: {
            dialogs: () => import('@/views/courses/CourseEditFormView.vue'),
          },
          meta: {
            title: 'Редактирование курса',
            icon: 'mdi-school-outline',
          },
        },
      ],
    },
    {
      path: '/courses/:courseId',
      name: 'course',
      component: () => import('@/views/courses/CourseView.vue'),
      meta: {
        title: 'Мероприятие',
        icon: 'mdi-school-outline',
      },
      children: [
        {
          path: 'students',
          name: 'course-students',
          component: () => import('@/views/courses/CourseStudentsView.vue'),
          meta: {
            title: 'Студенты курса',
          },
          children: [
            {
              path: 'manage',
              name: 'course-students-manage',
              components: {
                dialogs: () => import('@/views/courses/CourseStudentsAddUserFormView.vue'),
              },
            },
            {
              path: 'manage/:userId',
              name: 'course-students-manage-user',
              components: {
                dialogs: () => import('@/views/courses/CourseStudentsEditUserFormView.vue'),
              },
            },
          ],
        },
        {
          path: 'schedule',
          alias: '',
          name: 'course-schedule',
          component: () => import('@/views/courses/CourseScheduleView.vue'),
          meta: {
            title: 'Расписание курса',
          },
          children: [
            {
              path: 'new',
              name: 'course-lesson-new',
              components: {
                dialogs: () => import('@/views/courses/LessonEditFormView.vue'),
              },
              meta: {
                title: 'Информация по занятию',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/courses/:courseId/lessons/:lessonId',
      name: 'course-lesson',
      component: () => import('@/views/courses/LessonView.vue'),
      meta: {
        title: 'Информация по занятию',
      },
      children: [
        {
          path: 'camera/:cameraId',
          name: 'courses-lesson-camera',
          components: {
            camera: () => import('@/views/courses/LessonViewCameraPartial.vue'),
          },
        },
      ],
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/documents/DocumentsView.vue'),
      meta: {
        title: 'Обучающие материалы',
        icon: 'mdi-file-document-outline',
      },
    },

  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach(function (to, from, next) {
    const title = to?.meta?.title;
    // eslint-disable-next-line no-param-reassign
    document.title = title ? `${title} – ${siteName}` : siteName;
    next();
  });

  return router;
}
