import { TrainingMaterialDto } from '@/models/dto/TrainingMaterialsDto';

export class TrainingMaterial implements TrainingMaterialDto {
  name: string = '';
  url: string = '';

  private constructor() {
  }

  public static createFromDto(dto: TrainingMaterialDto): TrainingMaterial {
    return Object.assign(new this(), dto);
  }

  public static createCollectionFromDto(dtos: TrainingMaterialDto[]): TrainingMaterial[] {
    return dtos.map((dto) => Object.assign(new this(), dto));
  }
}
