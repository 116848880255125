






import { Component, Vue } from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';

@Component({
  components: { PageHeader1 },
})
export default class Home extends Vue {}
