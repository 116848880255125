import { PolygonAclDto } from '@/models/dto/PolygonAclDto';
import { User } from '@/models/entities/User';
import { UserRoleIdsIri } from '@/types/UserRoleIds';
import cloneDeep from 'lodash/cloneDeep';

export class PolygonAcl implements PolygonAclDto {
  '@id': string = '';
  user!: User;
  userRole!: UserRoleIdsIri;

  public static createFromDto(dto: PolygonAclDto) {
    const clone = cloneDeep(dto);
    return Object.assign(new this(), clone, {
      user: User.createFromDto(clone.user),
    });
  }
}
