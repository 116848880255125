import { CellFeatureDto } from '@/models/dto/CellFeatureDto';
import { RequireOne } from '@/types/RequiredOne';

export class CellFeature implements CellFeatureDto {
  '@id': string = '';
  cellFeatureId: string = '';
  cellFeatureName: string = '';
  currentValue: string = '';
  description: string = '';

  private _isDisplay: boolean = false;
  private _isTrafficLight: boolean = false;
  private _isBoolean: boolean = false;

  private constructor() {
  }

  public static createFromDto(dto: RequireOne<Partial<CellFeatureDto>, 'cellFeatureId'>): CellFeature {
    const instance = Object.assign(new this(), dto);
    if (!dto.cellFeatureId) {
      throw new Error('cellFeatureId is required to create a CellFeature instance');
    }
    if (dto.cellFeatureId.startsWith('display')) {
      instance._isDisplay = true;
    } else if (dto.cellFeatureId.startsWith('traffic')) {
      instance._isTrafficLight = true;
    } else {
      instance._isBoolean = true;
    }

    return instance;
  }

  get isDisplay(): boolean {
    return this._isDisplay;
  }

  get isTrafficLight(): boolean {
    return this._isTrafficLight;
  }

  get isBoolean(): boolean {
    return this._isBoolean;
  }
}
