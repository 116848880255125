import { UserDto } from '@/models/dto/UserDto';
import toPlainObject from 'lodash/toPlainObject';
import { UserUpdateDto, UserUpdateProfileDto } from '@/models/dto/UserUpdateDto';
import omit from 'lodash/omit';
import { AuthenticationRoles } from '@/types/AuthenticationRoles';
import { UserRolePolygonType } from '@/types/UserRolePolygonType';

export class User implements UserDto {
  '@id': string;
  userId: number = 0;
  email: string = '';
  firstName: string = '';
  middleName?: string;
  lastName: string = '';
  institutionName?: string;
  password: string = '';
  phone: string = '';
  createdAt?: Date;
  updatedAt?: Date;
  activatedAt?: Date;
  avatarUrl: string = '';
  roles: AuthenticationRoles[] = [AuthenticationRoles.ROLE_USER];
  sex!: 'male' | 'female';

  status: 'registered' | 'active' | 'deleted' | 'banned' = 'registered';

  userRolePolygons: UserRolePolygonType[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}${this.middleName ? ` ${this.middleName}` : ''}`;
  }

  get firstLetterOfName() {
    return this.firstName[0] || '';
  }

  get fio() {
    return `${this.lastName} ${this.firstName[0]}.${this.middleName ? `${this.middleName[0]}.` : ''}`;
  }

  get isSuperAdmin(): boolean {
    return this.roles.includes(AuthenticationRoles.ROLE_SUPERADMIN);
  }

  get isAdmin(): boolean {
    return this.roles.includes(AuthenticationRoles.ROLE_SUPERADMIN)
      || this.roles.includes(AuthenticationRoles.ROLE_ADMIN);
  }

  get isTeacher(): boolean {
    return this.roles.includes(AuthenticationRoles.ROLE_SUPERADMIN)
      || this.roles.includes(AuthenticationRoles.ROLE_ADMIN)
      || this.roles.includes(AuthenticationRoles.ROLE_TEACHER);
  }

  toDto(): UserDto {
    return toPlainObject(this);
  }

  toUpdateDto(): UserUpdateDto {
    const dto = this.toDto();

    return {
      ...omit(dto, ['password', 'status', 'userId']),
      userRolePolygons: this.userRolePolygons.map((r) => {
        if (r.polygon) {
          return {
            userRole: `/api/v1/user_roles/${encodeURIComponent(r.userRole.userRoleId)}`,
            polygon: `/api/v1/polygons/${encodeURIComponent(r.polygon.polygonId)}`,
          };
        }
        return {
          userRole: `/api/v1/user_roles/${encodeURIComponent(r.userRole.userRoleId)}`,
        };
      }),
    };
  }

  toUpdateProfileDto(): UserUpdateProfileDto {
    const dto = this.toDto();

    return {
      ...omit(dto, ['password', 'status', 'userId', 'userRolePolygons']),
    };
  }

  public static createFromDto(dto: Partial<UserDto>): User {
    return Object.assign(new this(), dto);
  }

  public static createCollectionFromDto(collection: UserDto[]) {
    return collection.map((dto) => this.createFromDto(dto));
  }
}
