import { AxiosInstance, AxiosResponse } from 'axios';
import { PolygonDto } from '@/models/dto/PolygonDto';
import { UserDto } from '@/models/dto/UserDto';
import { UserLoginResultDto } from '@/models/dto/UserLoginResultDto';
import { CourseDto } from '@/models/dto/CourseDto';
import { ActivationTokenDto } from '@/models/dto/ActivationTokenDto';
import { UUID } from '@/types/CommonTypes';
import { UserUpdateDto, UserUpdateProfileDto } from '@/models/dto/UserUpdateDto';
import { PolygonAclDto } from '@/models/dto/PolygonAclDto';
import { LessonFullDto } from '@/models/dto/LessonFullDto';
import { LessonUpdateDto } from '@/models/dto/LessonUpdateDto';
import { CameraDto } from '@/models/dto/CameraDto';
import { CameraCreateDto } from '@/models/dto/CameraCreateDto';
import { CourseUpdateDto } from '@/models/dto/CourseUpdateDto';
import { CellFeatureDto } from '@/models/dto/CellFeatureDto';
import { PolygonCreateDto } from '@/models/dto/PolygonCreateDto';
import { CellCreateDto } from '@/models/dto/CellCreateDto';
import { CellDto } from '@/models/dto/CellDto';
import { HydraCollection } from '@/api/types/HydraTypes';
import { UserPasswordDto } from '@/models/dto/UserPasswordDto';
import { CourseUserUpdateDto } from '@/models/dto/CourseUserDto';
import { CourseStatus } from '@/types/Statuses';
import { CellLeaseDto } from '@/models/dto/CellLeaseDto';
import { ResultBoolean } from '@/models/dto/ResultBoolean';
import { TrainingMaterialDto } from '@/models/dto/TrainingMaterialsDto';

export class ApiLayer {
  // eslint-disable-next-line
  constructor(private transport: AxiosInstance) {
  }

  getPolygon(polygonId: number) {
    return this.transport.get<PolygonDto>(`/polygons/${encodeURIComponent(polygonId)}`);
  }

  getPolygons() {
    return this.transport.get<HydraCollection<PolygonDto>>('/polygons');
  }

  getPolygonAcl(polygonId: number): Promise<AxiosResponse<HydraCollection<PolygonAclDto>>> {
    return this.transport.get<HydraCollection<PolygonAclDto>>(`/polygons/${encodeURIComponent(polygonId)}/acl`);
  }

  getUserCourses(userId: number): Promise<AxiosResponse<HydraCollection<CourseDto>>> {
    return this.transport.get<HydraCollection<CourseDto>>(`/users/${encodeURIComponent(userId)}/user_courses`);
  }

  getUserProfile(): Promise<AxiosResponse<UserDto>> {
    return this.transport.get<UserDto>('/user/profile');
  }

  // getCheckLogin(): Promise<AxiosResponse<UserLoginResultDto>> {
  //   return this.transport.get<UserLoginResultDto>('/login');
  // }

  postCheckLogin(email: string, password: string): Promise<AxiosResponse<UserLoginResultDto>> {
    return this.transport.post<UserLoginResultDto>('/login', {
      email,
      password,
    });
  }

  postSendPasswordReset(email: string): Promise<AxiosResponse<{ result: boolean }>> {
    return this.transport.post('/send_password_reset', { email });
  }

  postResendActivation(userId: number): Promise<AxiosResponse<{ result: boolean }>> {
    return this.transport.post(`/users/${userId}/resend_activation`, {}, {
      headers: { 'content-type': 'application/ld+json' },
    });
  }

  getActivationToken(tokenId: UUID): Promise<AxiosResponse<ActivationTokenDto>> {
    return this.transport.get<ActivationTokenDto>(`/activation_tokens/${encodeURIComponent(tokenId)}`);
  }

  postActivationToken(tokenId: UUID, password: string): Promise<AxiosResponse<{ result: true }>> {
    return this.transport.post<{ result: true }>(`/activation_tokens/${encodeURIComponent(tokenId)}`, {
      password,
    });
  }

  getUsers() {
    return this.transport.get<HydraCollection<UserDto>>('/users');
  }

  getTrainingMaterials() {
    return this.transport.get<TrainingMaterialDto[]>('/training_materials');
  }

  getUser(userId: number): Promise<AxiosResponse<UserDto>> {
    return this.transport.get<UserDto>(`/users/${encodeURIComponent(userId)}`);
  }

  putUser(userId: number, user: UserUpdateDto | UserUpdateProfileDto): Promise<AxiosResponse<UserDto>> {
    return this.transport.put<UserDto>(`/users/${encodeURIComponent(userId)}`, user);
  }

  postUser(user: UserUpdateDto): Promise<AxiosResponse<UserDto>> {
    return this.transport.post<UserDto>('/users', user);
  }

  deleteUser(userId: number): Promise<AxiosResponse<void>> {
    return this.transport.delete<void>(`/users/${encodeURIComponent(userId)}`);
  }

  getCourses(queryParams: {
    status?: CourseStatus;
    'polygon.polygonId'?: number;
    'groups[]'?: ['courses:item'];
  } | null = null): Promise<AxiosResponse<HydraCollection<CourseDto>>> {
    let qs = '';
    if (queryParams !== null) {
      const s = new URLSearchParams(queryParams as Record<string, string>);
      qs = s.toString();
    }
    return this.transport.get<HydraCollection<CourseDto>>(`/courses?${qs}`);
  }

  getCourse(courseId: number): Promise<AxiosResponse<CourseDto>> {
    return this.transport.get<CourseDto>(`/courses/${courseId}`);
  }

  getLesson(lessonId: number): Promise<AxiosResponse<LessonFullDto>> {
    return this.transport.get<LessonFullDto>(`/lessons/${encodeURIComponent(lessonId)}`);
  }

  // getCourseUsers(courseId: number) {
  //   return Promise.resolve(undefined);
  // }
  putLesson(lessonId: number, lessonUpdateDto: LessonUpdateDto): Promise<AxiosResponse<LessonFullDto>> {
    return this.transport.put<LessonFullDto>(`/lessons/${encodeURIComponent(lessonId)}`, lessonUpdateDto);
  }

  postLesson(lessonUpdateDto: LessonUpdateDto): Promise<AxiosResponse<LessonFullDto>> {
    return this.transport.post<LessonFullDto>('/lessons', lessonUpdateDto);
  }

  getPolygonCameras(polygonId: number): Promise<AxiosResponse<HydraCollection<CameraDto>>> {
    return this.transport.get<HydraCollection<CameraDto>>(`/polygons/${encodeURIComponent(polygonId)}/cameras`);
  }

  postCamera(cameraCreateDto: CameraCreateDto): Promise<AxiosResponse<CameraDto>> {
    return this.transport.post<CameraDto>('/cameras', cameraCreateDto);
  }

  putCamera(cameraId: number, cameraCreateDto: CameraCreateDto): Promise<AxiosResponse<CameraDto>> {
    return this.transport.put<CameraDto>(`/cameras/${encodeURIComponent(cameraId)}`, cameraCreateDto);
  }

  deleteCamera(cameraId: number): Promise<AxiosResponse<void>> {
    return this.transport.delete<void>(`/cameras/${encodeURIComponent(cameraId)}`);
  }

  getCellFeatures(): Promise<AxiosResponse<HydraCollection<CellFeatureDto>>> {
    return this.transport.get<HydraCollection<CellFeatureDto>>('/cell_features');
  }

  postPolygon(polygonCreateDto: PolygonCreateDto): Promise<AxiosResponse<PolygonDto>> {
    return this.transport.post<PolygonDto>('/polygons', polygonCreateDto);
  }

  putPolygon(polygonId: number, polygonCreateDto: PolygonCreateDto): Promise<AxiosResponse<PolygonDto>> {
    return this.transport.put<PolygonDto>(`/polygons/${encodeURIComponent(polygonId)}`, polygonCreateDto);
  }

  deletePolygon(polygonId: number): Promise<AxiosResponse<void>> {
    return this.transport.delete<void>(`/polygons/${encodeURIComponent(polygonId)}`);
  }

  postCell(cellCreateDto: CellCreateDto): Promise<AxiosResponse<CellDto>> {
    return this.transport.post<CellDto>('/cells', cellCreateDto);
  }

  putCell(cellId: number, cellCreateDto: CellCreateDto): Promise<AxiosResponse<CellDto>> {
    return this.transport.put<CellDto>(`/cells/${encodeURIComponent(cellId)}`, cellCreateDto);
  }

  deleteCell(cellId: number): Promise<AxiosResponse<void>> {
    return this.transport.delete<void>(`/cells/${encodeURIComponent(cellId)}`);
  }

  postCourses(courseDto: CourseUpdateDto): Promise<AxiosResponse<CourseDto>> {
    return this.transport.post('/courses', courseDto, {
      headers: { 'content-type': 'application/ld+json' },
    });
  }

  putCourse(courseId: number, updateCourseDto: CourseUpdateDto): Promise<AxiosResponse<CourseDto>> {
    return this.transport
      .put(`/courses/${encodeURIComponent(courseId)}`, updateCourseDto, {
        headers: { 'content-type': 'application/ld+json' },
      });
  }

  postAvatar(userId: number, file: File): Promise<AxiosResponse<{ avatarUrl: string }>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.transport.post<{ avatarUrl: string }>(`/users/${encodeURIComponent(userId)}/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  putUserPassword(userId: number, passwordForm: UserPasswordDto): Promise<AxiosResponse<void>> {
    return this.transport.put<void>(`/users/${encodeURIComponent(userId)}/password`, passwordForm);
  }

  postCourseUsers(dto: CourseUserUpdateDto) {
    return this.transport.post<void>('/course_users', dto);
  }

  putCourseUsers(id: string, updateDto: CourseUserUpdateDto) {
    return this.transport.put<void>(`/course_users/${encodeURIComponent(id)}`, updateDto);
  }

  async deleteCourseUser(courseUserRelId: string) {
    return this.transport.delete<void>(`/course_users/${encodeURIComponent(courseUserRelId)}`);
  }

  getCellLeases(params: { 'cell.polygon.polygonId'?: number; 'user.userId'?: number } = {}):
    Promise<AxiosResponse<HydraCollection<CellLeaseDto>>> {
    const q = new URLSearchParams();
    if (params['cell.polygon.polygonId']) {
      q.append('cell.polygon.polygonId', String(params['cell.polygon.polygonId']));
    }
    if (params['user.userId']) {
      q.append('user.userId', String(params['user.userId']));
    }
    return this.transport.get<HydraCollection<CellLeaseDto>>(`/cell_leases?${q.toString()}`);
  }

  postCellLeases(linkObj: { cell: string; user: string }): Promise<AxiosResponse<CellLeaseDto>> {
    return this.transport.post<CellLeaseDto>('/cell_leases', linkObj);
  }

  // deleteCellLeases(linkObj: { cell: any; user: any }) {
  //   return this.transport.delete<CellLeaseDto>('/cell_leases', linkObj);
  // }

  deleteUserCellLeases(userId: number): Promise<AxiosResponse<ResultBoolean>> {
    return this.transport.delete<ResultBoolean>(`/users/${encodeURIComponent(userId)}/cell_leases`);
  }

  postPasswordResetToken(token: string, password: string): Promise<AxiosResponse<ResultBoolean>> {
    return this.transport.post<ResultBoolean>(`/password_reset_tokens/${encodeURIComponent(token)}`, { password });
  }

  getPasswordResetToken(token: string): Promise<AxiosResponse<{ token: string }>> {
    return this.transport.get<{ token: string }>(`/password_reset_tokens/${encodeURIComponent(token)}`);
  }
}
