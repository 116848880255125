export enum CourseStatus {
  active = 'active',
  canceled = 'canceled'
}

export enum PolygonStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted'
}

export enum CellStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum CameraStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum LessonStatus {
  active = 'active',
  canceled = 'canceled',
  finished = 'finished'
}

export enum LessonStatusUiExtended {
  lessonTime = 'lessonTime',
  waitingForStart = 'waitingForStart'
}

export const LessonStatusNames: Record<LessonStatus | LessonStatusUiExtended, string> = {
  [LessonStatus.active]: 'Активный',
  [LessonStatus.canceled]: 'Отменен',
  [LessonStatus.finished]: 'Завершен',
  [LessonStatusUiExtended.lessonTime]: 'Идёт занятие',
  [LessonStatusUiExtended.waitingForStart]: 'Ожидание начала',
};

export const trafficLightStatuses: Record<number, string> = {
  0: 'Выключен',
  1: 'Красный',
  2: 'Жёлтый',
  3: 'Зелёный',
  4: 'Карусель',
};
