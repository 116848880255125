export enum UserRoleIds {
  teacher = 'teacher',
  student = 'student',
  admin = 'admin',
  superadmin = 'superadmin',
}

export enum UserRoleIdsIri {
  teacher = '/api/v1/user_roles/teacher',
  student = '/api/v1/user_roles/student',
  admin = '/api/v1/user_roles/admin',
  superadmin = '/api/v1/user_roles/superadmin',
}

export const UserRoleIriNames: Record<UserRoleIdsIri, string> = {
  [UserRoleIdsIri.admin]: 'Админ',
  [UserRoleIdsIri.student]: 'Студент',
  [UserRoleIdsIri.teacher]: 'Преподаватель',
  [UserRoleIdsIri.superadmin]: 'Суперадмин',
};

export const UserRoleIriIds: Record<UserRoleIdsIri, UserRoleIds> = {
  [UserRoleIdsIri.admin]: UserRoleIds.admin,
  [UserRoleIdsIri.student]: UserRoleIds.student,
  [UserRoleIdsIri.teacher]: UserRoleIds.teacher,
  [UserRoleIdsIri.superadmin]: UserRoleIds.superadmin,
};

// export const UserRoleNames: Record<UserRoleIds, string> = {
//   [UserRoleIds.admin]: 'Админ',
//   [UserRoleIds.student]: 'Студент',
//   [UserRoleIds.teacher]: 'Учитель',
//   [UserRoleIds.superadmin]: 'Суперадмин',
// };

export type RolesForCourse = UserRoleIdsIri.admin | UserRoleIdsIri.teacher | UserRoleIdsIri.student;
export type UserPolygonRoles = 'none' | UserRoleIdsIri.admin | UserRoleIdsIri.teacher | UserRoleIdsIri.student;
