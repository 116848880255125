import cloneDeep from 'lodash/cloneDeep';
import { CellStatus } from '@/types/Statuses';
import { CellDto } from '@/models/dto/CellDto';
import { CellCreateDto } from '@/models/dto/CellCreateDto';
import { CellFeature } from '@/models/entities/CellFeature';

export class Cell implements CellDto {
  '@id': string = '';
  cellId: number = 0;
  sequentialNumber: number = 0;
  status: CellStatus = CellStatus.active;
  polygon?: PolygonIriIdentifier;
  polygonId: number = 0;
  cellFeaturesIds: CellFeatureIriIdentifier[] = [];
  cellFeatures: CellFeature[] = [];

  public get isActive(): boolean {
    return this.status === CellStatus.active;
  }

  public set isActive(val: boolean) {
    if (val) {
      this.status = CellStatus.active;
    } else {
      this.status = CellStatus.inactive;
    }
  }

  public get displayName(): string {
    return `Ячейка: ${this.sequentialNumber}`;
  }

  toDto(): CellCreateDto {
    const cell: CellCreateDto = {
      sequentialNumber: this.sequentialNumber,
      status: this.status,
      cellFeatures: this.cellFeaturesIds,
    };

    if (this['@id']) {
      cell['@id'] = this['@id'];
      cell.cellId = this.cellId;
      cell.polygon = this.polygon;
    }

    if (this.polygon) {
      cell.polygon = this.polygon;
    }

    return cell;
  }

  public static createFromDto(dto: Partial<CellDto>): Cell {
    const clone = cloneDeep(dto);
    const cell = Object.assign(new this(), clone, {
      cellFeatures: (dto.cellFeatures || []).map((cf) => CellFeature.createFromDto(cf)),
    });
    cell.cellFeaturesIds = cell.cellFeatures.map((c) => {
      return c['@id'];
    });
    cell.cellFeatures.sort((a, b) => {
      if (a.cellFeatureName > b.cellFeatureName) {
        return 1;
      }
      if (a.cellFeatureName < b.cellFeatureName) {
        return -1;
      }
      return 0;
    });
    return cell;
  }
}
