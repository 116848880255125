import { DateAsString } from '@/types/CommonTypes';
import dayjs from 'dayjs';
import { LessonFullDto } from '@/models/dto/LessonFullDto';
import { CourseDto } from '@/models/dto/CourseDto';
import { LessonUpdateDto } from '@/models/dto/LessonUpdateDto';
import { LessonStatus } from '@/types/Statuses';

export class Lesson implements LessonFullDto {
  '@id'? = '';
  lessonId: number = 0;
  lessonDate: DateAsString = '';
  lessonDurationSeconds: number = 0;

  lessonDateDt!: Date;
  lessonFinishDt!: Date;

  lessonStartDay!: string;
  lessonStartTime!: string;

  lessonFinishDay!: string;
  lessonFinishTime!: string;
  description: string = '';
  course!: Pick<CourseDto, '@id' | 'courseId' | 'courseName' | 'polygon'>; // Pick<Course, 'courseName' | 'courseId' | 'polygon'>;
  status: LessonStatus = LessonStatus.active;

  get lessonDurationMinutes(): number {
    return Math.round(this.lessonDurationSeconds / 60);
  }

  get lessonDurationHumanized() {
    const d = dayjs.duration(this.lessonDurationSeconds, 'seconds');
    if (this.lessonDurationMinutes <= 60) {
      return `${d.asMinutes()} мин.`;
    }
    const hours = d.asHours();
    if (hours % 1 === 0) {
      return `${hours} час.`;
    }
    // eslint-disable-next-line no-bitwise
    return `${~~hours} ч. ${d.minutes()} мин.`;
  }

  toUpdateDto(): LessonUpdateDto {
    const dto: LessonUpdateDto = {
      course: this.course?.['@id'],
      description: this.description,
      lessonDate: this.lessonDate,
      lessonDurationSeconds: this.lessonDurationSeconds,
      status: this.status,
    };
    if (this['@id']) {
      dto['@id'] = this['@id'];
    }
    if (this.lessonId) {
      dto.lessonId = this.lessonId;
    }

    return dto;
  }

  updateDates(lessonDate: DateAsString, lessonDurationSeconds: number) {
    Object.assign(this, Lesson.createDatesFields(lessonDate, lessonDurationSeconds));
  }

  private static createDatesFields(lessonDate: DateAsString, lessonDurationSeconds: number):
    Pick<Lesson, 'lessonDate' | 'lessonDurationSeconds'
      | 'lessonDateDt' | 'lessonFinishDt'
      | 'lessonStartDay' | 'lessonStartTime' | 'lessonFinishDay' | 'lessonFinishTime'> {
    const lessonDateDt = new Date(lessonDate);
    const dStartDate = dayjs(lessonDate);
    const lessonStartDay = dStartDate.format('L');
    const lessonStartTime = dStartDate.format('LT');
    const dFinishDate = dStartDate.add(lessonDurationSeconds, 'second');
    const dates = {
      lessonDate,
      lessonDateDt,
      lessonStartDay,
      lessonStartTime,
      lessonFinishDt: dFinishDate.toDate(),
      lessonFinishDay: dFinishDate.format('L'),
      lessonFinishTime: dFinishDate.format('LT'),
      lessonDurationSeconds,
    };
    return dates;
  }

  public isLessonTime(dt: Date): boolean {
    return this.lessonDateDt <= dt && this.lessonFinishDt >= dt;
  }

  public static createFromDto(dto: Partial<LessonFullDto> & Pick<LessonFullDto, 'lessonDate' | 'lessonDurationSeconds'>) {
    const dates = this.createDatesFields(dto.lessonDate, dto.lessonDurationSeconds);
    const lesson = Object.assign(new this(), dto, {
      ...dates,
      description: dto.description || '',
    } as Partial<Lesson>);
    if (!lesson['@id']) {
      delete lesson['@id'];
    }
    return lesson;
  }
}
