import { ApiLayer } from '@/api/ApiLayer';
import { Polygon } from '@/models/entities/Polygon';
import { User } from '@/models/entities/User';
import { UserLoginResultDto } from '@/models/dto/UserLoginResultDto';
import { Course } from '@/models/entities/Course';
import { UUID } from '@/types/CommonTypes';
import { ActivationTokenDto } from '@/models/dto/ActivationTokenDto';
import { AxiosError } from 'axios';
import { UserUpdateDto } from '@/models/dto/UserUpdateDto';
import { PolygonAcl } from '@/models/entities/PolygonAcl';
import { Lesson } from '@/models/entities/Lesson';
import { Camera } from '@/models/entities/Camera';
import { CellFeatureDto } from '@/models/dto/CellFeatureDto';
import { Cell } from '@/models/entities/Cell';
import { CourseUpdateDto } from '@/models/dto/CourseUpdateDto';
import { UserPasswordDto } from '@/models/dto/UserPasswordDto';
import { RolesForCourse } from '@/types/UserRoleIds';
import { CourseUserUpdateDto } from '@/models/dto/CourseUserDto';
import { CourseStatus } from '@/types/Statuses';
import { CellLease } from '@/models/entities/CellLease';
import { TrainingMaterial } from '@/models/entities/TrainingMaterial';

export class ApiFacade {
  constructor(private api: ApiLayer) {
  }

  async fetchPolygonInfo(polygonId: number): Promise<Polygon> {
    return this.api.getPolygon(polygonId)
      .then((r) => Polygon.createFromDto(r.data));
  }

  async fetchAllPolygons(): Promise<Polygon[]> {
    try {
      const collection = await this.api.getPolygons()
        .then((res) => res.data['hydra:member']);
      return Polygon.createCollectionFromDto(collection);
    } catch (e) {
      console.error('fetching polygons error', e);
    }
    return [];
  }

  async fetchProfile() {
    const { data } = await this.api.getUserProfile();
    return User.createFromDto(data);
  }

  async fetchUsers() {
    const collection = await this.api.getUsers()
      .then((r) => r.data);
    return User.createCollectionFromDto(collection['hydra:member']);
  }

  async fetchTrainingMaterials() {
    const collection = await this.api.getTrainingMaterials()
      .then((r) => r.data);
    return TrainingMaterial.createCollectionFromDto(collection);
  }

  async checkLogin(email: string, password: string): Promise<UserLoginResultDto> {
    try {
      const result = await this.api.postCheckLogin(email, password);
      return result.data;
    } catch (err) {
      if (err.response?.data) {
        throw new Error(
          err.response.data.error || err.response.data.message || 'Ошибка авторизации',
        );
      }
      throw err;
    }
  }

  fetchFinishRegistrationToken(tokenId: UUID): Promise<ActivationTokenDto> {
    return this.api.getActivationToken(tokenId)
      .then((result) => result.data);
  }

  activateUser(token: string, password: string): Promise<boolean> {
    return this.api.postActivationToken(token, password)
      .then((result) => Boolean(result.data?.result))
      .catch((err: AxiosError) => {
        if (err.response?.data) {
          throw new Error(
            err.response.data.error || err.response.data.message || 'Ошибка подтверждения',
          );
        }
        throw err;
      });
  }

  async createNewUser(user: User): Promise<User> {
    const createUserObj: UserUpdateDto = user.toUpdateDto();

    return this.api.postUser(createUserObj)
      .then((r) => User.createFromDto(r.data));
  }

  async updateUser(user: User) {
    return this.api.putUser(user.userId, user.toUpdateDto())
      .then((r) => User.createFromDto(r.data));
  }

  async findUserByEmail(email: string) {
    const emailLc = email.toLowerCase();
    const users = await this.fetchUsers();
    return users.find((u) => u.email.toLowerCase() === emailLc) || null;
  }

  async fetchUsersRolesOfPolygon(polygon: Polygon): Promise<PolygonAcl[]> {
    return this.api.getPolygonAcl(polygon.polygonId)
      .then((res) => {
        return res.data['hydra:member'].map((r) => PolygonAcl.createFromDto(r));
      });
  }

  async fetchLesson(lessonId: number) {
    return this.api.getLesson(lessonId)
      .then((res) => Lesson.createFromDto(res.data));
  }

  fetchCourses(params: { polygonId?: number; status?: CourseStatus; includeUsers?: boolean } | null = null): Promise<Course[]> {
    let queryParams: Parameters<ApiLayer['getCourses']>[0] = null;
    if (params) {
      queryParams = {};
      if (params.status) {
        queryParams.status = params.status;
      }
      if (params.polygonId) {
        queryParams['polygon.polygonId'] = params.polygonId;
      }
      if (params.includeUsers) {
        queryParams['groups[]'] = ['courses:item'];
      }
    }

    return this.api.getCourses(queryParams)
      .then((res) => {
        return res.data['hydra:member'].map((dto) => Course.createFromDto(dto));
      });
  }

  async fetchCourse(courseId: number): Promise<Course> {
    return this.api.getCourse(courseId)
      .then((r) => {
        return Course.createFromDto(r.data);
      });
  }

  async updateLesson(lesson: Lesson): Promise<Lesson> {
    return this.api.putLesson(lesson.lessonId, lesson.toUpdateDto())
      .then((r) => {
        return Lesson.createFromDto(r.data);
      });
  }

  createLesson(lesson: Lesson): Promise<Lesson> {
    return this.api.postLesson(lesson.toUpdateDto())
      .then((r) => {
        return Lesson.createFromDto(r.data);
      });
  }

  async fetchPolygonCameras(polygon: Pick<Polygon, 'polygonId'>): Promise<Camera[]> {
    return this.api.getPolygonCameras(polygon.polygonId)
      .then((res) => {
        return res.data['hydra:member'].map((c) => Camera.createFromDto(c));
      });
  }

  async createCamera(camera: Camera): Promise<Camera> {
    return this.api.postCamera(camera.toCreateDto())
      .then((c) => {
        return Camera.createFromDto(c.data);
      });
  }

  async updateCamera(camera: Camera): Promise<Camera> {
    return this.api.putCamera(camera.cameraId, camera.toCreateDto())
      .then((c) => {
        return Camera.createFromDto(c.data);
      });
  }

  async deleteCamera(camera: Camera): Promise<void> {
    await this.api.deleteCamera(camera.cameraId);
  }

  async fetchCellFeatures(): Promise<CellFeatureDto[]> {
    return this.api.getCellFeatures()
      .then((res) => {
        return res.data['hydra:member'];
      });
  }

  async createPolygon(polygon: Polygon): Promise<Polygon> {
    return this.api.postPolygon(polygon.toCreateDto())
      .then((p) => {
        return Polygon.createFromDto(p.data);
      });
  }

  async updatePolygon(polygon: Polygon): Promise<Polygon> {
    return this.api.putPolygon(polygon.polygonId, polygon.toUpdateDto())
      .then((p) => {
        return Polygon.createFromDto(p.data);
      });
  }

  async deletePolygon(polygon: Polygon): Promise<void> {
    await this.api.deletePolygon(polygon.polygonId);
  }

  async createCell(cell: Cell): Promise<Cell> {
    return this.api.postCell(cell.toDto())
      .then((p) => {
        return Cell.createFromDto(p.data);
      });
  }

  async updateCell(cell: Cell): Promise<Cell> {
    return this.api.putCell(cell.cellId, cell.toDto())
      .then((p) => {
        return Cell.createFromDto(p.data);
      });
  }

  async deleteCell(cell: Cell): Promise<void> {
    await this.api.deleteCell(cell.cellId);
  }

  createCourse(course: Course): Promise<Course> {
    const courseCreateDto: CourseUpdateDto = course.toUpdateDto({ includeLessons: true });

    return this.api.postCourses(courseCreateDto)
      .then((res) => {
        return Course.createFromDto(res.data);
      });
  }

  async updateCourse(course: Course, includeLessons: boolean = false): Promise<Course> {
    const updateCourseDto = course.toUpdateDto({ includeLessons });
    return this.api.putCourse(course.courseId, updateCourseDto)
      .then((r) => Course.createFromDto(r.data));
  }

  async uploadAvatar(user: User, file: File): Promise<string> {
    const res = await this.api.postAvatar(user.userId, file);
    return res.data.avatarUrl;
  }

  async updatePassword(user: User, passwordForm: UserPasswordDto): Promise<void> {
    await this.api.putUserPassword(user.userId, passwordForm);
  }

  async updateProfile(user: User) {
    return this.api.putUser(user.userId, user.toUpdateProfileDto())
      .then((r) => User.createFromDto(r.data));
  }

  async deleteUser(user: User): Promise<void> {
    await this.api.deleteUser(user.userId);
  }

  async postResendActivation(user: User): Promise<void> {
    await this.api.postResendActivation(user.userId);
  }

  async fetchUser(userId: number): Promise<User> {
    return this.api.getUser(userId)
      .then((r) => User.createFromDto(r.data));
  }

  updateCourseUserRelation(course: Course, user: User, role: RolesForCourse) {
    const dto: CourseUserUpdateDto = {
      course: course['@id'],
      user: user['@id'],
      userRole: role,
    };
    const rel = course.courseUsers.find((r) => r.user.userId === user.userId);

    if (!rel) {
      return this.api.postCourseUsers(dto);
    }

    const courseUserRelId = `course=${encodeURIComponent(course.courseId)};`
      + `user=${encodeURIComponent(user.userId)};`
      + `userRole=${encodeURIComponent(rel.userRole.userRoleId)}`;
    return this.api.putCourseUsers(courseUserRelId, dto);
  }

  async removeCourseUserRelation(course: Course, user: User): Promise<void> {
    const rel = course.courseUsers.find((r) => r.user.userId === user.userId);
    if (!rel) {
      return;
    }
    // rel.userRole
    const courseUserRelId = `course=${encodeURIComponent(course.courseId)};`
      + `user=${encodeURIComponent(user.userId)};`
      + `userRole=${encodeURIComponent(rel.userRole.userRoleId)}`;
    await this.api.deleteCourseUser(courseUserRelId);
  }

  async fetchCellLeasesForPolygon(polygon: Pick<Polygon, 'polygonId'>, user?: Pick<User, 'userId'>): Promise<CellLease[]> {
    const params: { 'cell.polygon.polygonId': number; 'user.userId'?: number } = {
      'cell.polygon.polygonId': polygon.polygonId,
    };
    if (user && user.userId) {
      params['user.userId'] = user.userId;
    }
    return this.api
      .getCellLeases(params)
      .then((response) => {
        return response.data['hydra:member'] || [];
      })
      .then((res) => {
        return res.map((cl) => CellLease.createFromDto(cl));
      });
  }

  linkUserAndCell(user: User, cell: Cell): Promise<CellLease> {
    return this.api
      .postCellLeases({
        user: user['@id'],
        cell: cell['@id'],
      })
      .then((response) => CellLease.createFromDto(response.data));
  }

  // async unlinkUserAndCell(user: User, cell: Cell) {
  //   return this.api
  //     .deleteCellLeases({
  //       user: user['@id'],
  //       cell: cell['@id'],
  //     })
  //     .then((response) => CellLease.createFromDto(response.data));
  // }

  unlinkAllCellsFromUser(user: User): Promise<boolean> {
    return this.api.deleteUserCellLeases(user.userId)
      .then((response) => {
        return response.data?.result === true;
      });
  }

  async createRestoreToken(email: string): Promise<boolean> {
    try {
      const result = await this.api.postSendPasswordReset(email);
      return Boolean(result.data?.result);
    } catch (err) {
      if (err?.response?.status === 404) {
        throw new Error('Пользователь не найден');
      }
      if (err.isAxiosError) {
        throw new Error(
          err.response.data.error || err.response.data.message || 'Ошибка',
        );
      }
      throw err;
    }
  }

  newPassword(token: string, password: string): Promise<boolean> {
    return this.api.postPasswordResetToken(token, password)
      .then((result) => {
        return Boolean(result.data?.result);
      });
  }

  checkRestorePasswordToken(token: string): Promise<boolean> {
    return this.api.getPasswordResetToken(token)
      .then((result) => {
        return result.data?.token === token;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }
}
