import { CourseDto } from '@/models/dto/CourseDto';
import { Lesson } from '@/models/entities/Lesson';
import { PolygonDto } from '@/models/dto/PolygonDto';
import { UserRoleDto } from '@/models/dto/UserRoleDto';
import { User } from '@/models/entities/User';
import { CourseUpdateDto } from '@/models/dto/CourseUpdateDto';
import { Polygon } from '@/models/entities/Polygon';
import { CourseStatus, LessonStatus } from '@/types/Statuses';
import { UserRoleIds } from '@/types/UserRoleIds';

export class Course implements CourseDto {
  '@id': string = '';
  description: string = '';
  courseId: number = 0;
  courseName: string = '';
  polygon!: Pick<PolygonDto, '@id' | 'polygonId' | 'name'>;
  status: CourseStatus = CourseStatus.active;
  lessons: Lesson[] = [];
  courseUsers: {
    user: User;
    userRole: UserRoleDto<UserRoleIds.student | UserRoleIds.teacher | UserRoleIds.admin>;
  }[] = [];

  isCourseAdmin(user: User): boolean {
    return this.courseUsers.findIndex((rel) => {
      return rel.user.userId === user.userId && rel.userRole.userRoleId === UserRoleIds.admin;
    }) !== -1;
  }

  isCourseTeacher(user: User): boolean {
    return this.courseUsers.findIndex((rel) => {
      return rel.user.userId === user.userId && rel.userRole.userRoleId === UserRoleIds.teacher;
    }) !== -1;
  }

  isCourseStudent(user: User): boolean {
    return this.courseUsers.findIndex((rel) => {
      return rel.user.userId === user.userId && rel.userRole.userRoleId === UserRoleIds.student;
    }) !== -1;
  }

  public static createFromDto(dto: Partial<CourseDto>): Course {
    const lessons = dto.lessons
      ?.map((s) => Lesson.createFromDto(s))
      .sort((a, b) => (a.lessonDate < b.lessonDate ? -1 : 1));
    return Object.assign(new this(), dto, {
      lessons: lessons || [],
      courseUsers: dto.courseUsers?.map((r) => ({
        user: User.createFromDto(r.user),
        userRole: r.userRole,
      })) || [],
    });
  }

  /**
   * name with polygon name
   */
  get fullName() {
    let name = this.courseName;
    if (this.polygon) {
      name += ` / ${this.polygon.name}`;
    }
    return name;
  }

  get firstLessonDate(): Date | null {
    if (this.lessons.length > 0) {
      return this.lessons[0].lessonDateDt;
    }
    return null;
  }

  get startDate(): string | '' {
    if (this.lessons.length > 0) {
      return this.lessons[0].lessonStartDay || '';
    }
    return '';
  }

  get finishDate(): string | '' {
    if (this.lessons.length > 0) {
      return this.lessons[this.lessons.length - 1].lessonFinishDay || '';
    }
    return '';
  }

  get admins(): User[] {
    return this.courseUsers
      .filter((rel) => rel.userRole.userRoleId === UserRoleIds.admin)
      .map((rel) => rel.user);
  }

  get students(): User[] {
    return this.courseUsers
      .filter((rel) => rel.userRole.userRoleId === UserRoleIds.student)
      .map((rel) => rel.user);
  }

  get teachers(): User[] {
    return this.courseUsers
      .filter((rel) => rel.userRole.userRoleId === UserRoleIds.teacher)
      .map((rel) => rel.user);
  }

  get activeLessons(): Lesson[] {
    return this.lessons.filter((l) => l.status === LessonStatus.active);
  }

  toUpdateDto({ includeLessons }: { includeLessons: boolean } = { includeLessons: false }): CourseUpdateDto {
    const dto: CourseUpdateDto = {
      courseName: this.courseName,
      description: this.description,
      status: this.status,
      polygon: Polygon.getIriIdentifier(this.polygon),
    };
    if (includeLessons) {
      dto.lessons = this.lessons.map((l) => l.toUpdateDto()) || [];
    }
    return dto;
  }

  hasUser(user: User): boolean {
    return this.courseUsers.findIndex((rel) => rel.user.userId === user.userId) !== -1;
  }
}
