import { CameraDto } from '@/models/dto/CameraDto';
import { CameraStatus } from '@/types/Statuses';
import cloneDeep from 'lodash/cloneDeep';
import { CameraCreateDto } from '@/models/dto/CameraCreateDto';
import { Cell } from '@/models/entities/Cell';

export class Camera implements Omit<CameraDto, 'cell'> {
  '@id': string = '';
  cameraId: number = 0;
  cameraVideoId: string = '';
  cameraName: string = '';
  description: string = '';
  status: CameraStatus = CameraStatus.active;
  polygon?: PolygonIriIdentifier;
  cell: Pick<Cell, '@id' | 'cellId' | 'sequentialNumber'> | null = null;

  get cellSequentialNumber(): number | undefined {
    return this.cell?.sequentialNumber;
  }

  toCreateDto(): CameraCreateDto {
    return {
      cameraVideoId: this.cameraVideoId,
      cameraName: this.cameraName,
      description: this.description,
      status: this.status,
      polygon: this.polygon,
      cell: this.cell?.['@id'] || null,
    };
  }

  public static createFromDto(dto: Partial<CameraDto>): Camera {
    const clone = cloneDeep(dto);
    return Object.assign(new this(), clone, {
      cell: clone.cell ? Cell.createFromDto(clone.cell) : null,
    });
  }
}
